import React from "react"
import {graphql, StaticQuery} from "gatsby"
import ArchiveLayout from "../components/archivelayout"
import SEO from "../components/seo"
import Sidebar from "../components/sidebar"
import PaginationLinks from "../components/PaginationLinks"
import {Container, Row, Col} from "reactstrap"
import Post from "../components/Post"
import styled from "styled-components"
import {colors, fonts} from "../../theme.js"

const postList = () => {
  const postsPerPage = 8
  let numberOfPages

  return (
    <ArchiveLayout>
      <SEO
        title="Blog | Spark Files"
        description="This is the full Spark Files blog archive. Articles on the origin of words, phrases and customs."
        keywords={[`etymology`, `customs`]}
      />
      <Container fluid={true} className="p-0">
        <Row className="m-0">
          <Col className="p-0">
            <TitleBar>
              <h1 className="text-center" style={{fontFamily: fonts.mainFont}}>
                Spark Files <span style={{color: colors.meatRare}}>Blog</span>
              </h1>
            </TitleBar>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md="8">
            <StaticQuery
              query={indexQuery}
              render={data => {
                numberOfPages = Math.ceil(data.allMdx.totalCount / postsPerPage)
                return (
                  <div>
                    {data.allMdx.edges.map(({node}) => (
                      <Post
                        title={node.frontmatter.title}
                        sizes={
                          node.frontmatter.featuredImage.childImageSharp.gatsbyImageData
                        }
                        date={node.frontmatter.date}
                        body={node.frontmatter.excerpt}
                        slug={node.fields.slug}
                        tags={node.frontmatter.tags}
                      />
                    ))}
                    <PaginationLinks
                      currentPage={1}
                      numberOfPages={numberOfPages}
                    />
                  </div>
                );
              }}
            />
          </Col>

          <Col md="4">
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </ArchiveLayout>
  );
}

const indexQuery = graphql`query indexQuery {
  allMdx(
    sort: {order: DESC, fields: [frontmatter___date]}
    limit: 8
  ) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          tags
          excerpt
          path
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 500
                height: 280
                quality: 90
                tracedSVGOptions: {background: "#290849", color: "#ffd524"}
                placeholder: TRACED_SVG
                layout: CONSTRAINED
              )
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`
export default postList

const TitleBar = styled.div`
  background-image: linear-gradient(316deg, ${colors.meatChar} 0%, #131313 84%);
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  p {
    color: #fff !important;
  }
  a {
    color: #f56545;
    &:hover {
      color: #d25337 !important;
    }
  }
`
